<template>
	<div class="sidebar">
		<div class="logo-img">
			<div class="logo">
				<img class="img" src="../assets/img/qizhijia2.png" alt="" />
				<img v-if="!collapse" class="text" src="../assets/img/logoimg.png" alt="" />
			</div>
		</div>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-if="role == 'admin'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics">公司统计报表</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="reportNumber">
				<template v-slot:title="title">
					<i class="el-icon-phone"></i>
					<span>号码分类</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="reportNumber">报备绿名单号码</el-menu-item>
					<el-menu-item index="woniunumberPool">蜗牛号码池</el-menu-item>
					<el-menu-item index="commercialNumberPool">三五号码池</el-menu-item>
					<el-menu-item index="woniuBill">蜗牛话单</el-menu-item>
					<el-menu-item index="shutdownRecord">停机记录</el-menu-item>
					<el-menu-item index="gmAbnormalCallList">国美异常话单</el-menu-item>
					<el-menu-item index="gmAbnormalNumber">国美异常号码</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="WNAbnormalCallList">
				<template v-slot:title="title">
					<i class="el-icon-s-tools"></i>
					<span>蜗牛管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="wnQuery">蜗牛查询</el-menu-item>
					<el-menu-item index="WNrestartRecordList">蜗牛停复机</el-menu-item>
					<el-menu-item index="accountOpeningProgress">蜗牛开户进度</el-menu-item>
					<el-menu-item index="WNAbnormalCallList">蜗牛异常话单</el-menu-item>
					<el-menu-item index="WNWhitelist">蜗牛批量加白</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="termOfValidity">
				<i class="el-icon-time"></i>
				<span>有效期管理</span>
			</el-menu-item>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-submenu index="agent">
				<template v-slot:title="title">
					<i class="el-icon-set-up"></i>
					<span>管理员权限</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="agent">代理商管理</el-menu-item>
					<el-menu-item index="agentSerial">代理商分配记录</el-menu-item>
					<el-menu-item index="flowManagement">流水管理</el-menu-item>
					<el-menu-item index="numberPool">号码池</el-menu-item>
					<el-menu-item index="stop">号码停机</el-menu-item>
					<el-menu-item index="seating">坐席数据</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i class="el-icon-s-tools"></i>
					<span>系统设置</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType">品牌管理</el-menu-item>
					<el-menu-item index="blackListDatabase">黑名单库</el-menu-item>
					<el-menu-item index="userSerial">员工分配记录</el-menu-item>
					<el-menu-item index="role">角色管理</el-menu-item>
					<el-menu-item index="operationLog">员工操作日志</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="notice">
				<template v-slot:title="title">
					<i class="el-icon-chat-dot-round"></i>
					<span>公告管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="notice">公告列表</el-menu-item>
					<el-menu-item index="addNotice">发布公告</el-menu-item>
					<el-menu-item index="swipes">轮播图列表</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="afterSales">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'agent'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="WNrestartRecordList">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/woniu.png" alt="" />
					<span
						:class="[onRoutes == 'WNrestartRecordList' ? theme3 : '', onRoutes == 'WNAbnormalCallList' ? theme3 : '', onRoutes == 'wnQuery' ? theme3 : '']">
						蜗牛管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="wnQuery" :class="onRoutes == 'wnQuery' ? theme6 : ''">蜗牛查询</el-menu-item>
					<el-menu-item index="WNrestartRecordList"
						:class="onRoutes == 'WNrestartRecordList' ? theme6 : ''">蜗牛停复机</el-menu-item>
					<el-menu-item index="WNAbnormalCallList"
						:class="onRoutes == 'WNAbnormalCallList' ? theme6 : ''">异常话单</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics">公司统计报表</el-menu-item>
					<el-menu-item index="seating">坐席数据</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i class="el-icon-s-tools"></i>
					<span>系统设置</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType">品牌管理</el-menu-item>
					<el-menu-item index="blackListDatabase">黑名单库</el-menu-item>
					<el-menu-item index="userSerial">员工分配记录</el-menu-item>
					<el-menu-item index="role">角色管理</el-menu-item>
					<el-menu-item index="flowManagement">流水管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="swipes">
				<i class="el-icon-chat-dot-round"></i>
				<span>轮播图列表</span>
			</el-menu-item>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="afterSales">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>

		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'company'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>

			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'normal'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-menu-item index="customer">
				<i class="el-icon-user"></i>
				<span>CRM管理</span>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-menu-item>
			<el-menu-item index="clue">
				<i class="el-icon-data-line"></i>
				<span>线索管理</span>
			</el-menu-item>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>

		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'department'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>

			<el-menu-item index="customer">
				<i class="el-icon-user"></i>
				<span>CRM管理</span>
			</el-menu-item>

			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="department">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="record">
				<i class="el-icon-phone-outline"></i>
				<span>话务管理</span>
			</el-menu-item>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>

			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>

			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
	</div>
</template>

<script>
	// import bus from "../common/bus";
	export default {
		data() {
			return {
				lastLoginIp: '',
				lastLoginAt: '',
				roleName: '',
				role: ''
			};
		},
		computed: {
			onRoutes() {
				return this.$route.path.replace('/', '');
			},
			collapse() {
				return this.$store.state.collapse;
			}
		},
		created() {
			this.lastLoginIp = localStorage.getItem('lastLoginIp');
			this.lastLoginAt = localStorage.getItem('lastLoginAt');
			let role = localStorage.getItem('ms_role');
			this.role = localStorage.getItem('ms_role');
		},
		methods: {
			// 侧边栏折叠
			collapseChage() {
				this.$store.commit('hadndleCollapse', !this.collapse);
			}
		}
	};
</script>
<style scoped>
	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 70px;
	}

	.logo-img .logo {
		float: left;
		height: 100%;
		width: 216px;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #6c6cf9;
	}

	.logo-img .logo .img {
		width: 42px;
		margin: 0 15px 0 20px;
	}

	.logo-img .logo .text {
		width: 94px;
	}

	.logo-img {
		box-sizing: border-box;
		width: 216px;
		height: 70px;

		font-size: 22px;
		margin-bottom: 20px;
		color: #666;
	}

	.sidebar {
		display: block;
		position: absolute;
		width: 216px;
		left: 0;
		top: 0;
		bottom: 0;
		overflow-y: scroll;
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
	}

	.sidebar-el-menu:not(.el-menu--collapse) {
		width: 216px;
	}

	.sidebar>ul {
		height: calc(100%-70px);
	}

	.el-menu--collapse .el-menu-item i {
		position: relative;
		left: -38px;
	}

	.login_info {
		display: block;
		position: fixed;
		left: 10px;
		bottom: 12px;

		color: #000;
	}

	.login_info div {
		font-size: 12px;
	}
</style>
<style>
	.el-menu--collapse * {
		text-align: center !important;
	}

	.el-menu-item .el-menu-item-group__title,
	.el-submenu .el-menu-item-group__title {
		padding: 0;
	}

	.el-menu .el-menu-item,
	.el-menu .el-submenu {
		margin: 0 auto !important;
	}

	.el-menu .el-menu-item.is-active {
		border-radius: 4px;
		color: #fff;
		background-color: #4f7afd !important;
	}

	.el-menu .el-menu-item:hover {
		color: #fff !important;
		background-color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-submenu__title {
		color: #4f7afd !important;
	}

	.el-submenu .el-submenu__title:hover {
		background-color: #fff !important;

		color: #4f7afd !important;
	}

	.el-submenu .el-submenu__title:hover i {
		color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-submenu__title i {
		color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-menu .el-menu-item:hover {
		border-radius: 4px !important;
		color: #fff !important;
		background-color: #4f7afd !important;
	}

	.el-menu .el-menu-item:hover i {
		color: #fff;
	}

	.el-submenu__title,
	.el-menu-item,
	.el-submenu .el-menu-item {
		height: 46px !important;
		line-height: 46px !important;
	}

	.el-submenu__title,
	.el-submenu .el-menu-item {
		padding-left: 50px !important;
	}

	.el-menu-item {
		border-radius: 4px !important;
		width: 93.5% !important;
		min-width: 150px !important;
		background-color: red;
	}

	.el-submenu__title {
		padding-left: 27px !important;
	}
</style>