import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/dashboard'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [{
			path: "/dashboard",
			name: "dashboard",
			meta: {
				title: '系统首页'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/Dashboard.vue")
		},
		{
			path: "/customer",
			name: "customer",
			meta: {
				title: 'CRM列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/customer.vue")
		},
		{
			path: "/clue",
			name: "baseTable",
			meta: {
				title: '线索列表'
				// title: '客户列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/clue.vue")
		},
		{
			path: "/assignLeads",
			name: "assignLeads",
			meta: {
				title: '线索回收分配'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/assignLeads.vue")
		},

		{
			path: "/record",
			name: "record",
			meta: {
				title: '通话记录'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/record.vue")
		},
		{
			path: "/WNAbnormalCallList",
			name: "WNAbnormalCallList",
			meta: {
				title: '蜗牛异常话单',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNAbnormalCallList.vue")
		},
		{
			path: "/wnQuery",
			name: "wnQuery",
			meta: {
				title: '蜗牛查询',
				permission: true,
				role: ['admin', 'agent', 'subAgent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/wnQuery.vue")
		}, {
			path: "/accountOpeningProgress",
			name: "accountOpeningProgress",
			meta: {
				title: '蜗牛开户进度',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/accountOpeningProgress.vue")
		},
		{
			path: "/WNWhitelist",
			name: "WNWhitelist",
			meta: {
				title: '蜗牛批量加白',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNWhitelist.vue")
		},
		{
			path: "/WNrestartRecordList",
			name: "WNrestartRecordList",
			meta: {
				title: '蜗牛停复机',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/WNrestartRecordList.vue")
		},
		{
			path: "/tag",
			name: "tag",
			meta: {
				title: '标签管理',
			},
			component: () => import(
				"../views/tag.vue")
		},
		{
			path: "/company",
			name: "company",
			meta: {
				title: '企业管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/company.vue")
		},
		{
			path: "/department",
			name: "department",
			meta: {
				title: '部门管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/department.vue")
		}, {
			path: "/user",
			name: "user",
			meta: {
				title: '员工管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/user.vue")
		}, {
			path: "/report",
			name: "report",
			meta: {
				title: '员工统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'oem', 'department']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/report.vue")
		}, {
			path: "/companyStatistics",
			name: "companyStatistics",
			meta: {
				title: '公司统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'oem', 'department']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/companyStatistics.vue")
		}, {
			path: "/agent",
			name: "agent",
			meta: {
				title: '代理管理',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agent.vue")
		},
		{
			path: "/blackListDatabase",
			name: "blackListDatabase",
			meta: {
				title: '黑名单库',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackListDatabase.vue")
		},
		{
			path: "/cardType",
			name: "cardType",
			meta: {
				title: '品牌管理',
				permission: true,
				role: ['admin', 'agent', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/cardType.vue")
		},
		{
			path: "/numberPool",
			name: "numberPool",
			meta: {
				title: '号码池',
				permission: true,
				role: ['admin', 'oem'],
				/* , 'agent' */
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/numberPool.vue")
		}, {
			path: "/commercialNumberPool",
			name: "commercialNumberPool",
			meta: {
				title: '三五号码池',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/commercialNumberPool.vue")
		}, {
			path: "/woniunumberPool",
			name: "woniunumberPool",
			meta: {
				title: '蜗牛号码池',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniunumberPool.vue")
		}, {
			path: "/woniuBill",
			name: "woniuBill",
			meta: {
				title: '蜗牛话单',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniuBill.vue")
		}, {
			path: "/shutdownRecord",
			name: "shutdownRecord",
			meta: {
				title: '停机记录',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/shutdownRecord.vue")
		}, {
			path: "/agentSerial",
			name: "agentSerial",
			meta: {
				title: '代理商分配记录',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agentSerial.vue")
		}, {
			path: "/userSerial",
			name: "userSerial",
			meta: {
				title: '员工分配记录',
				permission: true,
				role: ['admin', 'agent', 'oem'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userSerial.vue")
		}, {
			path: "/flowManagement",
			name: "flowManagement",
			meta: {
				title: '流水管理',
				permission: true,
				role: ['admin', 'agent', 'oem'],
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/flowManagement.vue")
		},
		{
			path: "/stop",
			name: "stop",
			meta: {
				title: '号码停机',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/stopPhone.vue")
		}, {
			path: "/validate",
			name: "validate",
			meta: {
				title: '号码验证'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/validate.vue")
		},
		{
			path: "/blackList",
			name: "blackList",
			meta: {
				title: '黑名单'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackList.vue")
		},
		{
			path: "/userManual",
			name: "userManual",
			meta: {
				title: '用户手册'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userManual.vue")
		},
		{
			path: "/swipes",
			name: "swipes",
			meta: {
				title: '轮播图列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/swipes.vue")
		},
		{
			path: "/afterSales",
			name: "afterSales",
			meta: {
				title: '售后信息',
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/afterSales.vue")
		},
		{
			path: "/role",
			name: "role",
			meta: {
				title: '角色管理',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/role.vue")
		}, {
			path: "/notice",
			name: "notice",
			meta: {
				title: '公告',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/notice.vue")
		},
		{
			path: "/addNotice",
			name: "addNotice",
			meta: {
				title: '发布公告',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/addNotice.vue")
		},
		{
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import( /* webpackChunkName: "404" */
				'../views/404.vue')
		}, {
			path: '/403',
			name: '403',
			meta: {
				title: '没有权限'
			},
			component: () => import( /* webpackChunkName: "403" */
				'../views/403.vue')
		},
		{
			path: "/operationManual",
			name: "operationManual",
			meta: {
				title: '使用手册'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/operationManual.vue")
		},
		{
			path: "/reportNumber",
			name: "reportNumber",
			meta: {
				title: '报备绿名单号码',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/reportNumber.vue")
		},
		{
			path: "/operationLog",
			name: "operationLog",
			meta: {
				title: '员工操作日志',
				permission: true,
				role: ['admin', 'agent', 'oem'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/operationLog.vue")
		},
		{
			path: "/termOfValidity",
			name: "termOfValidity",
			meta: {
				title: '有效期管理',
				permission: true,
				role: ['admin', 'agent', 'oem'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/termOfValidity.vue")
		},
		{
			path: "/seating",
			name: "seating",
			meta: {
				title: '坐席数据',
				permission: true,
				role: ['admin', 'agent']
			},
			component: () => import(
				"../views/seating.vue")
		},
		{
			path: "/customTag",
			name: "customTag",
			meta: {
				title: '字段配置',
			},
			component: () => import(
				"../views/customTag.vue")
		},
		{
			path: "/followProgress",
			name: "followProgress",
			meta: {
				title: '跟进阶段配置',
			},
			component: () => import(
				"../views/followProgress.vue")
		},
		{
			path: "/source",
			name: "source",
			meta: {
				title: '来源配置',
			},
			component: () => import(
				"../views/source.vue")
		},
		{
			path: "/release",
			name: "release",
			meta: {
				title: '释放管理',
			},
			component: () => import(
				"../views/release.vue")
		},
		{
			path: "/gmAbnormalCallList",
			name: "gmAbnormalCallList",
			meta: {
				title: '国美异常话单',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalCallList.vue")
		},
		{
			path: "/gmAbnormalNumber",
			name: "gmAbnormalNumber",
			meta: {
				title: '国美异常号码',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalNumber.vue")
		},
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录',
		loginRequest: true
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];
// 35个
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 企智家`;
	const role = localStorage.getItem('ms_role');
	if (!role && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已

		to.meta.role.indexOf(role) >= 0 ? next() : next('/403');
	} else {
		next();
	}
});
// router.afterEach(function(to,form){
//
// })

export default router;